<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable && isOld" 
                  label="삭제" 
                  icon="delete"
                  @btnClicked="deleteData" />
                      <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="budgetResult"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="isOld"
                  :editable="editable"
                  name="plantCd" 
                  v-model="budgetResult.plantCd"
                  @datachange="setSumAmtList(false)" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :disabled="isOld"
                  label="집행년월"
                  name="year"
                  type="month"
                  v-model="budgetResult.budgetMonth"
                  @datachange="setSumAmtList(false)" 
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-dept
                  required
                  :editable="editable"
                  label="부서"
                  name="budgetDeptCd"
                  v-model="budgetResult.budgetDeptCd"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-12">
          <q-toolbar class="bg-primary text-white">
            <q-toolbar-title> {{$language('항목별 집행금액 누적집행액/편성')}} </q-toolbar-title>
          </q-toolbar>
          <q-list bordered padding class="row">
            <div v-for="(item, idx) in planItems"
              :key="idx" class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <q-item>
                <q-item-section>
                  <q-item-label>
                    <b>{{ $language(item.budgetItemName) }}</b>
                  </q-item-label>
                  <q-item-label caption class="text-deep-orange">
                    {{item.stackAmount | toThousandFilter}} / {{item.budgetPlanAmt | toThousandFilter}}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
            </div>
          </q-list>
        </div>
        <div class="col-12">
          <c-table
            ref="table"
            title="예산집행 목록"
            tableId="resultItems"
            :columns="grid.columns"
            :data="budgetResult.resultItems"
            gridHeight="500px"
            :editable="editable"
            :columnSetting="false"
            :expandAll="false"
            :isFullScreen="false"
            :usePaging="false"
            :hideBottom="true"
            :filtering="false"
            rowKey="budgetResultItemId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addItem" />
                <c-btn v-if="editable&&budgetResult.resultItems.length>0" label="제외" icon="remove" @btnClicked="deleteItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'budget-result-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        budgetResultId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: '',
      budgetResult: {
        plantCd: null,
        budgetMonth: '',
        budgetDeptCd: '',
        budgetAllAmt: 0,
        resultItems: [],
      },
      vendor: {},
      mappingType: 'POST',
      detailUrl: '',
      yearListUrl: '',
      deleteUrl: '',
      saveUrl: '',
      checkUrl: '',
      deleteDeptUrl: '',
      editable: true,
      isSave: false,
      isVendorSave: false,
      isSubmit: false,
      isConfirm: false,
      isReturn: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
        ],
      },
      planItems: [],
      endDate: '',
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 260);
    },
    isOld() {
      return Boolean(this.popupParam.budgetResultId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.budgetResult.budgetMonth = this.$comm.getThisMonth();
      // url setting
      this.detailUrl = selectConfig.sai.budget.result.get.url
      this.yearListUrl = selectConfig.sai.budget.year.plant.url
      this.checkUrl = selectConfig.sai.budget.result.check.url
      this.saveUrl = transactionConfig.sai.budget.result.insert.url
      this.deleteUrl = transactionConfig.sai.budget.result.delete.url
      this.deleteDeptUrl = transactionConfig.sai.budget.result.delete.url
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.budgetResultId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.budgetResultId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.budgetResult = _result.data
          this.setSumAmtList(false);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.setSumAmtList(true);
      }
    },
    setSumAmtList(isNew) {
      this.$http.url = this.yearListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.$store.getters.user.plantCd,
        budgetMonth: this.budgetResult.budgetMonth,
      }
      this.$http.request((_result) => {
        if (isNew) {
          this.$set(this.budgetResult, 'plantCd', _result.data.plantCd)
          // this.$set(this.budgetResult, 'budgetMonth', _result.data.budgetMonth)
        }
        if (this.budgetResult.budgetMonth) {
          let year = this.budgetResult.budgetMonth.substring(0,4)
          let month = this.budgetResult.budgetMonth.substring(5)
          let day = new Date(year, month, 0).getDate();

          this.endDate = year + '-' + month + '-' + day
        }
        this.$set(this.$data, 'planItems', _result.data.planItems)
        this.setHeader();
      },);
    },
    setHeader() {
      let comboItems = [];
      this.$_.forEach(this.planItems, _item => {
        comboItems.push({
          code: _item.budgetYearPlanItemId,
          codeName: this.$language(_item.budgetItemName)
        })
      })
      this.grid.columns = [
        {
          setHeader: true,
          required: true,
          name: 'budgetYearPlanItemId',
          field: 'budgetYearPlanItemId',
          label: '항목',
          align: 'left',
          style: 'width: 250px',
          sortable: false,
          type: 'select',
          comboItems: comboItems,
        },
        {
          setHeader: true,
          required: true,
          name: 'budgetDate',
          field: 'budgetDate',
          label: '집행일',
          align: 'center',
          type: 'date',
          start: this.budgetResult.budgetMonth + '-01',
          end: this.endDate,
          style: 'width:150px',
          sortable: true,
        },
        { 
          setHeader: true,
          required: true,
          name: 'budgetResultAmt',
          field: 'budgetResultAmt',
          label: '예산 집행금액(원)',
          align: 'right',
          style: 'width: 150px',
          sortable: false,
          type: 'number'
        },
        {
          name: 'budgetContent',
          field: 'budgetContent',
          label: '집행내용',
          style: 'width: 350px',
          align: 'left',
          sortable: false,
          type: 'textarea'
        },
        {
          name: 'attach',
          field: 'attach',
          label: '항목별 첨부',
          align: 'center',
          style: 'width: 250px',
          type: 'attach',
          taskClassCd: 'SAI_BUDGET_RESULT_ITEM',
          keyText: 'budgetResultItemId',
          sortable: false,
        },
      ]
    },
    saveData() {
      if (this.popupParam.budgetResultId) {
        this.saveUrl = transactionConfig.sai.budget.result.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.budget.result.insert.url
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.budgetResult.resultItems)) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: this.budgetResult.plantCd,
              budgetMonth: this.budgetResult.budgetMonth,
              budgetDeptCd: this.budgetResult.budgetDeptCd,
              budgetResultId: this.popupParam.budgetResultId,
            };
            this.$http.request((_result) => {
              if (_result.data > 0 && this.mappingType === 'POST') {
                window.getApp.$emit('ALERT', {
                  title: '안내',
                  message: '동일한 년월의 부서별 예산집행내역이 있습니다.',  
                  type: 'warning', // success / info / warning / error
                });
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: '확인', 
                  message: '저장하시겠습니까?',  
                  
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.budgetResult.regUserId = this.$store.getters.user.userId;
                    this.budgetResult.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.budgetResultId = result.data
      this.getDetail();
      this.getState();
    },
    /* eslint-disable no-unused-vars */ 
    addItem() {
      if (!this.budgetResult.resultItems) this.budgetResult.resultItems = [];
      this.budgetResult.resultItems.push({
        budgetResultId: this.popupParam.budgetResultId,
        budgetResultItemId: uid(),
        budgetDate: '',
        budgetResultAmt: 0,
        budgetContent: '',
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    deleteItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.budgetResult.deleteResultItems) this.budgetResult.deleteResultItems = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.budgetResult.deleteResultItems, { budgetYearPlanItemId: item.budgetYearPlanItemId })) {
              this.budgetResult.deleteResultItems.push(item)
          }
          this.budgetResult.resultItems = this.$_.reject(this.budgetResult.resultItems, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.budgetResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
